import { useMemo } from 'react';
import { useDateFormatter, useDateTransformer } from '@drivekyte/date-utils';
import { Place } from '@drivekyte/use-places-search';
import { useRouter } from 'next/router';
import { QueryBookingCart } from '@/types/booking-cart';
import { LiveDataSocialProofParams } from '@/types/live-data-social-proof';
import { getNextEndDate } from '@/utils/date-formatter';
import { RequestError } from '@/utils/request';
import { getIsMappingToLot } from '@/utils/get-mapping-to-lot/get-is-mapping-to-lot';
import useAnalyticsWithBookingCart from '../use-analytics-with-booking-cart-data';
import useApiError from '../use-api-error';
import useBookingCart from '../use-booking-cart';
import useLiveDataSocialProof from '../use-live-data-social-proof';
import useQuotes from '../use-quotes';
import useSearchInputState from '../use-search-input-state';
import useSuggestedHandover, {
  HandoverOutpostType,
  getEnhancedSuggestedPlace,
} from '../use-suggested-handover';
import useTimeSlots from '../use-time-slots';

type UseSearchProps = {
  disableTimeslotFetching?: boolean;
  disableQuoteFetching?: boolean;
};

const useSearch = ({
  disableTimeslotFetching,
  disableQuoteFetching = true,
}: UseSearchProps = {}) => {
  const router = useRouter();
  const { trackEvent, AnalyticsEvents } = useAnalyticsWithBookingCart();
  const showError = useApiError();
  const { formatISOWithoutTimezone } = useDateFormatter();
  const { getDateInServiceAreaTz } = useDateTransformer();
  const { inputState, setInputState } = useSearchInputState();
  const { toQuery, updateBookingCart, ...bookingCart } = useBookingCart();
  const { refetch: fetchQuotes, isFetching } = useQuotes({
    disabled: disableQuoteFetching,
  });

  const {
    state: handoverOutpostState,
    checkHandoverAddress,
    acceptHandoverSuggestion,
    rejectHandoverSuggestions,
    toggleConfirmationStatus,
  } = useSuggestedHandover();

  const {
    endAddress,
    endDate,
    endTz,
    isSameDeliveryReturnLocations,
    startAddress,
    startDate,
    startTz,
    serviceAreaUUID,
    endServiceAreaUUID,
    isStartAddressPickupAtLot,
    isEndAddressPickupAtLot,
    isMappingEndToPickupAtLot,
    isMappingStartToPickupAtLot,
  } = bookingCart;

  const {
    data: timeSlotsData,
    startTimeSlotsTimestamps,
    endTimeSlotsTimestamps,
    isLoading: isLoadingTimeSlots,
    isStartDateValid,
    isEndDateValid,
    timeSlotsStartError,
    timeSlotsEndError,
  } = useTimeSlots({
    endAddress: endAddress || startAddress,
    startAddress,
    endDate,
    startDate,
    disabled: disableTimeslotFetching,
    serviceAreaUUID,
    endServiceAreaUUID,
    startHandoverType:
      isStartAddressPickupAtLot || isMappingStartToPickupAtLot
        ? 'pual'
        : undefined,
    endHandoverType:
      isEndAddressPickupAtLot || isMappingEndToPickupAtLot ? 'pual' : undefined,
  });

  const liveDataParams = useMemo(() => {
    const toDateNumber = (date: Date, timezone: string) => {
      if (!date) return undefined;

      return getDateInServiceAreaTz(date.getTime(), timezone);
    };

    const params: LiveDataSocialProofParams = {
      client_component_id: 'search_bar',
      service_area_uuid: bookingCart.serviceAreaUUID,
      start_date: toDateNumber(startDate, bookingCart.startTz),
      end_date: toDateNumber(endDate, bookingCart.startTz),
      vehicle_class: bookingCart.vehicleClass,
    };

    return params;
  }, [
    bookingCart.serviceAreaUUID,
    bookingCart.startTz,
    bookingCart.vehicleClass,
    endDate,
    getDateInServiceAreaTz,
    startDate,
  ]);

  const socialLiveData = useLiveDataSocialProof(liveDataParams);

  const isFormValidForLocation =
    startDate &&
    endDate &&
    startAddress?.length >= 2 &&
    endAddress?.length >= 2;

  const areDatesValid =
    !!startTz &&
    !!endTz &&
    isStartDateValid &&
    isEndDateValid &&
    !timeSlotsStartError &&
    !timeSlotsEndError;

  const isFormValid = isFormValidForLocation && areDatesValid;

  const onChangeStartAddress = (
    address: Place,
    hasDateFieldFocus: boolean = true,
  ) => {
    const mappedStartAddress =
      address.map_to?.display_text || address.display_text;

    const isMappingToPickupAtLot = getIsMappingToLot(address);

    let changes: Partial<QueryBookingCart> = {
      start_address: mappedStartAddress,
      start_place: address.display_text,
      start_address_exclusion_zone_id: address.map_to?.exclusion_zone?.uuid,
      service_area_uuid: address.service_area_uuid,
      service_area_code: address.service_area_code,
      is_mapping_start_to_pickup_at_lot: isMappingToPickupAtLot,
    };

    if (isSameDeliveryReturnLocations) {
      changes = {
        ...changes,
        end_address: mappedStartAddress,
        end_place: address.display_text,
        end_address_exclusion_zone_id: address.map_to?.exclusion_zone?.uuid,
        end_service_area_uuid: address.service_area_uuid,
        end_service_area_code: address.service_area_code,
        is_mapping_end_to_pickup_at_lot: isMappingToPickupAtLot,
      };
    }

    updateBookingCart(changes);
    checkHandoverAddress(HandoverOutpostType.DELIVERY, address);

    if (hasDateFieldFocus) {
      setInputState({
        showStartDate: true,
        showStartAddress: false,
      });
    }
  };

  const onChangeEndAddress = (
    address: Place,
    hasDateFieldFocus: boolean = true,
  ) => {
    const isMappingToPickupAtLot = getIsMappingToLot(address);

    let changes: Partial<QueryBookingCart> = {
      end_address: address.map_to?.display_text || address.display_text,
      end_place: address.display_text,
      end_address_exclusion_zone_id: address.map_to?.exclusion_zone?.uuid,
      end_service_area_uuid: address.service_area_uuid,
      end_service_area_code: address.service_area_code,
      is_mapping_end_to_pickup_at_lot: isMappingToPickupAtLot,
    };

    updateBookingCart(changes);

    checkHandoverAddress(HandoverOutpostType.RETURN, address);

    if (hasDateFieldFocus) {
      setInputState({
        showStartDate: true,
        showEndAddress: false,
      });
    }
  };

  const onChangeStartDate = (
    nextSelectedDate: Date | undefined,
    origin: 'date-picker' | 'time-picker',
  ) => {
    if (!nextSelectedDate) return;

    updateBookingCart({
      start_date: formatISOWithoutTimezone(nextSelectedDate),
      end_date: formatISOWithoutTimezone(
        getNextEndDate(nextSelectedDate, endDate),
      ),
    });
    setInputState({
      showStartDate: origin === 'date-picker',
      showEndDate: origin === 'time-picker',
    });
  };

  const onChangeEndDate = (
    nextSelectedDate: Date | undefined,
    origin: 'date-picker' | 'time-picker',
  ) => {
    if (!nextSelectedDate) return;

    onChange({
      end_date: formatISOWithoutTimezone(nextSelectedDate),
    });
    setInputState({
      showEndDate: origin === 'date-picker',
    });
  };

  const onChangeAddressToggle = (nextToggleValue: boolean) => {
    if (nextToggleValue) {
      updateBookingCart({
        same_delivery_and_return: nextToggleValue,
        end_address: bookingCart.startAddress,
        end_place: bookingCart.startPlace,
        end_service_area_uuid: bookingCart.serviceAreaUUID,
        end_service_area_code: bookingCart.serviceAreaCode,
        is_mapping_end_to_pickup_at_lot:
          bookingCart.isMappingStartToPickupAtLot,
      });

      return;
    }

    updateBookingCart({
      same_delivery_and_return: nextToggleValue,
    });
  };

  const onChange = (nextValues: Partial<QueryBookingCart>) => {
    updateBookingCart(nextValues);
  };

  const onSubmitWithQuotes = async (
    event?:
      | React.FormEvent<EventTarget>
      | React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event?.preventDefault();

    const shouldQuoteForOneWays =
      router.pathname !== '/car-picker' &&
      !bookingCart.isSameDeliveryReturnLocations;

    if (shouldQuoteForOneWays) {
      const { error } = await fetchQuotes();
      if (error) return showError(error as RequestError);
    }

    onSubmit();
  };

  const onSubmit = (
    event?:
      | React.FormEvent<EventTarget>
      | React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ): void => {
    event?.preventDefault();

    const hasSuggestionPending = !!handoverOutpostState.handoverOutpostType;
    if (hasSuggestionPending) {
      toggleConfirmationStatus();
    } else if (isFormValid) {
      const nextQuery = toQuery();

      trackEvent(AnalyticsEvents.BookingFlow.SearchSubmitted, {
        ...nextQuery,
        end_date: endDate.toISOString(),
        start_date: startDate.toISOString(),
      });
      if (
        window.location.href.includes('car-picker') ||
        window.location.href.includes('checkout')
      ) {
        return;
      }
      router.push(
        {
          pathname: '/car-picker',
          query: nextQuery,
        },
        undefined,
        { shallow: true },
      );
    }
  };

  const handleAcceptHandoverSuggestion = () => {
    const {
      handoverOutpostType,
      handoverOutpostStart,
      handoverOutpostEnd,
      requestedStart,
      requestedEnd,
    } = handoverOutpostState;
    let startSuggestedPlace: Place, endSuggestedPlace: Place;
    if (handoverOutpostStart) {
      startSuggestedPlace = getEnhancedSuggestedPlace(
        requestedStart!,
        handoverOutpostStart,
      );
    }
    if (handoverOutpostEnd) {
      endSuggestedPlace = getEnhancedSuggestedPlace(
        requestedEnd!,
        handoverOutpostEnd,
      );
    }

    switch (handoverOutpostType) {
      case HandoverOutpostType.RETURN:
        onChangeEndAddress(endSuggestedPlace!, false);
        break;
      case HandoverOutpostType.DELIVERY:
        onChangeStartAddress(startSuggestedPlace!, false);
        break;
    }

    acceptHandoverSuggestion();
  };

  return {
    bookingCart,
    isFetchingQuotes: isFetching,
    isFormValid,
    isLoadingTimeSlots,
    inputState,
    timeSlotsData,
    startTimeSlotsTimestamps,
    endTimeSlotsTimestamps,
    timeSlotsStartError,
    timeSlotsEndError,
    onChange,
    onChangeAddressToggle,
    onChangeStartAddress,
    onChangeEndAddress,
    onChangeStartDate,
    onChangeEndDate,
    setInputState,
    onSubmit,
    onSubmitWithQuotes,
    socialLiveData,
    socialLiveDataParams: liveDataParams,
    handoverOutpostState,
    acceptHandoverSuggestion: handleAcceptHandoverSuggestion,
    rejectHandoverSuggestions,
  };
};

export default useSearch;
